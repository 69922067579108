<template>
    <picture
        v-observe-visibility="lazy
            ? {
                callback: visibilityChanged,
                intersection: {
                    rootMargin: '0px 0px 100% 0px'
                },
                once: true
            }
            : false
        "
    >
        <slot />
    </picture>
</template>

<script>
const lazyAttrs = ['media', 'srcset', 'sizes'];
const lazyAttrPrefix = 'data';

export default {
    name: 'BasePicture',

    props: {
        lazy: {
            type: Boolean,
            default: true,
        },
    },

    created() {
        if (this.lazy) this.sourceAttrsAssignData();
    },

    methods: {
        sourceAttrsAssignData(nodes = this.$slots.default) {
            nodes.filter((node) => node.tag === 'source')
                .forEach((node) => {
                    Object.keys(node.data.attrs)
                        .filter((attr) => lazyAttrs.includes(attr))
                        .forEach((attrKey) => {
                            Object.assign(node.data.attrs, {
                                [`${lazyAttrPrefix}-${attrKey}`]: node.data.attrs[attrKey],
                                [attrKey]: false,
                            });
                        });
                });
        },

        visibilityChanged(isVisible, entry) {
            if (!isVisible) return;

            const sources = entry.target.querySelectorAll('source');
            sources.forEach((source) => {
                lazyAttrs.forEach((attr) => {
                    const attrValue = source.getAttribute(`${lazyAttrPrefix}-${attr}`);
                    if (attrValue) {
                        source.setAttribute(`${attr}`, attrValue);
                        source.removeAttribute(`${lazyAttrPrefix}-${attr}`);
                    }
                });
            });
        },
    },
};
</script>
