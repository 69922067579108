var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "picture",
    {
      directives: [
        {
          name: "observe-visibility",
          rawName: "v-observe-visibility",
          value: _vm.lazy
            ? {
                callback: _vm.visibilityChanged,
                intersection: {
                  rootMargin: "0px 0px 100% 0px",
                },
                once: true,
              }
            : false,
          expression:
            "lazy\n        ? {\n            callback: visibilityChanged,\n            intersection: {\n                rootMargin: '0px 0px 100% 0px'\n            },\n            once: true\n        }\n        : false\n    ",
        },
      ],
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }